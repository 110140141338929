<template>
    <footer>  
        <p>{{$t('footer')}}</p>
    </footer>
</template>

<style>
    footer {
        background: #0250e3;
        text-align: center;
        color: white;
        min-width: 100vw;
    }
</style>
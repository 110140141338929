<template>
  <div class="row skill">
    <div class="row skill-career">
      <CareerProfile v-if="responseInformationCV"
        :responseInformationCV="responseInformationCV"
      ></CareerProfile>
    </div>
    <div class="row skill-graphic">
      <Graphics
        v-if="responseInformationCV"
        :responseInformationCV="responseInformationCV"
      ></Graphics>
    </div>
  </div>
</template>

<script>
import CareerProfile from "./CareerProfile.vue";
import Graphics from "./Graphics.vue";
export default {
  components: {
    Graphics,
    CareerProfile,
  },
  props: ["responseInformationCV"],
};
</script>

<style>
.skill-graphic {
  height: 70%;
}
.skill-career {
  height: 30%;
}
.skill {
  height: 100%;
}
@media (min-width: 1024px) {
  .skill-graphic {
    height: 60%;
    justify-content: space-around;
  }
  .skill-career {
    height: 40%;
  }
  .skill {
    height: 100%;
  }
}
@media (min-width: 320px) and (max-width: 1023px) {
  .skill-graphic {
    height: auto;
  }
  .skill-career {
    height: auto;
  }
  .skill {
    height: auto;
  }
}
</style>
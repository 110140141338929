<template>
  <div class="graphics">
    <vue3-chart-js v-bind="{ ...barChart }" />
  </div>
</template>

<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";

export default {
  name: "App",
  components: {
    Vue3ChartJs,
  },
  props: ["responseInformationCV"],
  setup(props) {
    const barChart = {
      type: "bar",
      options: {
        min: 0,
        max: 100,
        responsive: true,
        plugins: {
          legend: {
            position: "Bottom",
          },
        },
        scales: {
          y: {
            min: 0,
            max: 100,
            ticks: {
              callback: function (value) {
                return `${value}%`;
              },
            },
          },
        },
      },
      data: {
        labels: ["Angular", "Thymeleaf", "Vue", "Node", "Python", "Java"],
        datasets: [
          {
            label: "Experiencia",
            backgroundColor: [
              "#c3002f",
              "#c3cf21",
              "#49d191",
              "#3d3f34",
              "#3771a1",
              "#e87002",
            ],
            data: [
              props.responseInformationCV.technologies.TECHNOLOGIES.Angular,
              props.responseInformationCV.technologies.TECHNOLOGIES.Thymeleaf,
              props.responseInformationCV.technologies.TECHNOLOGIES.Vue,
              props.responseInformationCV.technologies.TECHNOLOGIES.Node,
              props.responseInformationCV.technologies.TECHNOLOGIES.Python,
              props.responseInformationCV.technologies.TECHNOLOGIES.Java,
            ],
          },
        ],
      },
    };

    const beforeRenderLogic = (event) => {
      //...
      //if(a === b) {
      //  event.preventDefault()
      //}
    };

    return {
      barChart,
      beforeRenderLogic,
    };
  },
};
</script>

<style>

@media (min-width: 1024px) {
  .graphics {
    height:450px;
    width: 100%;
    display: flex;
    flex-direction:column;
    justify-content: space-around;
  }
}
@media (min-width: 1440px) {
  .graphics {
    height:550px;
    width: 100%;
    display: flex;
    flex-direction:column;
    justify-content: space-around;
  }
}
</style>
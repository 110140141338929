<template>
  <div>
    <div class="career">
      <h5>{{ $t("careeerProfile.skills") }}</h5>
      <ul>
        <li>
          {{
            responseInformationCV.careerProfile.CAREER_PROFILES.DESCRIPTION_DB
          }}
        </li>
        <li>
          {{
            responseInformationCV.careerProfile.CAREER_PROFILES
              .DESCRIPTION_PROGRAMMING
          }}
        </li>
        <li>
          {{
            responseInformationCV.careerProfile.CAREER_PROFILES
              .DESCRIPTION_CERTIFICATIONS
          }}
        </li>
        <li>
          {{
            responseInformationCV.careerProfile.CAREER_PROFILES
              .DESCRIPTION_OTHERS
          }}
        </li>
        <li>
          {{
            responseInformationCV.careerProfile.CAREER_PROFILES.DESCRIPTION_OS
          }}
        </li>
        <li>
          {{
            responseInformationCV.careerProfile.CAREER_PROFILES.DESCRIPTION_IA
          }}
        </li>
      </ul>
    </div>
    <div class="certifications">
      <h5>{{ $t("careeerProfile.certification") }}</h5>
      <ul>
        <li>
          <img src="https://dxl34urpxiq9n.cloudfront.net/iconos/aws.png" height="25" alt="AWS" onclick="window.open('https://www.credly.com/badges/8871a14d-b3bd-4f5c-be43-c5fe16c48c51?source=linked_in_profile')" style="cursor:pointer;">
          {{ responseInformationCV.certfications.CERTIFICATIONS.DESCRIPTION_1 }},
          {{ responseInformationCV.certfications.CERTIFICATIONS.START_DATE_1 }}
          - {{ responseInformationCV.certfications.CERTIFICATIONS.END_DATE_1 }},
          ID: <a href="https://aw.certmetrics.com/amazon/public/verification.aspx" target="_blank">  {{
            responseInformationCV.certfications.CERTIFICATIONS
              .ID_CERTIFICATION_1
          }}</a>,
          {{ responseInformationCV.certfications.CERTIFICATIONS.INSTITUTION_1 }}
        </li>
        <li>
          <img src="https://dxl34urpxiq9n.cloudfront.net/iconos/python.png" height="25" alt="AWS" onclick="window.open('https://www.credly.com/badges/ae58421d-99ff-4b4b-9396-4d43a85dd041?source=linked_in_profile')" style="cursor:pointer;">
          {{ responseInformationCV.certfications.CERTIFICATIONS.DESCRIPTION_2 }},
          {{ responseInformationCV.certfications.CERTIFICATIONS.START_DATE_2 }},
          ID: <a href="https://verify.openedg.org" target="_blank"> {{
            responseInformationCV.certfications.CERTIFICATIONS
              .ID_CERTIFICATION_2
          }}</a>,
          {{ responseInformationCV.certfications.CERTIFICATIONS.INSTITUTION_2 }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["responseInformationCV"],
};
</script>

<style>
.career {
  margin-top: 15px;
}
.certifications {
  margin-top: 10px;
}
</style>
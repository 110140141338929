<template>
  <div class="general" ondragstart="return false" onselectstart="return false" oncontextmenu="return false">
    <Header />
    <div class="row components">
      <div class="col-sm-12 content-box">
        <GeneralComponent />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Header from "@/components/Header.vue";
import GeneralComponent from "@/components/GeneralComponent.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Header,
    GeneralComponent,
    Footer,
  },
  methods: {
    ...mapActions(["getInformationCV"])
  },
  mounted() {
    if (localStorage.getItem("lang") == "es" || localStorage.getItem("lang") == null) {
      this.getInformationCV({ id: 1, idLanguage: 1 });
    } else {
      this.getInformationCV({ id: 1, idLanguage: 2 });
    }
  },
  created () {
    const load = localStorage.getItem("load");
    if (load !== "cargado") {
      this.getInformationCV({ id: 1, idLanguage: 1 });
      localStorage.setItem("load", "cargado");
    }
  },
};
</script>

<style>
.general {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  min-height: 100vh;
}

.components {
  display: flex;
  flex-grow: 1;
  flex-wrap: nowrap;
}
.content-box {
  box-sizing: content-box;
  padding: 5px;
  height: 85%;
  border-radius: 25px;
}

@media (min-width: 320px) and (max-width: 767.98px) {
  .content-box {
    height: auto;
    width: 350px;
    margin-bottom: 30px;
  }

  .components {
    align-self: center;
  }
}
@media (min-width: 320px) and (max-width: 1023.99px) {
  .components {
    margin-bottom: 50px;
  }
}

</style>

<template>
  <div class="div-picture">
    <img
      v-bind:src="responseInformationCV.personalData.URL_S3"
      class="picture"
      alt="Foto de Curriculum"
    />
  </div>
  <div class="info">
    <h3> {{ responseInformationCV.personalData.NAME }} {{ responseInformationCV.personalData.LASTNAME }} {{ responseInformationCV.personalData.SECONDNAME }}</h3>
    <h4 v-if="lang == 'es'">
      {{ responseInformationCV.education.EDUCACION.EDUCATION_LEVEL_2 }}
    </h4>
    <h4 v-else>
      {{ responseInformationCV.education.EDUCATION.EDUCATION_LEVEL_2 }}
    </h4>
    <h5>{{ responseInformationCV.personalData.AGE }} {{ $t("profile.age") }}</h5>
    <h5>{{ responseInformationCV.personalData.ESTATE }}</h5>
    <a href="mailto:eduardosolorzano92@gmail.com" target="_blank"
      ><h5>{{ responseInformationCV.personalData.EMAIL }}</h5></a
    >
    <h5><a href="tel:{{ responseInformationCV.personalData.CEL }}">{{ responseInformationCV.personalData.CEL }}</a></h5>
  </div>
</template>

<script>
import Profile from "./Profile.vue";
import Skills from "./Skills.vue";
export default {
  components: { Profile, Skills },
  props: ["responseInformationCV"],
  data() {
    const lang = localStorage.getItem("lang") || "es";
    return {
      lang: lang,
    };
  },
};
</script>

<style scoped>
.div-picture {
  text-align: center;
}
.picture {
  height: 380px;
  margin-top: 30px;
  border-radius: 25px;
  margin-bottom: 5px;
  box-shadow: 4px 5px 4px -1px rgba(0,0,0,0.74);
}

.info {
  overflow-wrap: break-word;
  text-align: center;
  margin-top: 30px;
}

h3 {
  color: #0250E3;
}
</style>
import { createStore } from 'vuex'

export default createStore({
  state: {
    responseToken : null,
    responseInformationCV : null,
    load: false
  },
  mutations: {
    getToken(state, response){
      state.responseToken = response
    },
    getInformationCV(state, response){
      state.responseInformationCV = response
    },
    setLoad(state, value){
      state.load = value
    }
  },
  actions: {
    async getToken({commit}, {id, idLanguage}){
      try{
        const response = await fetch('https://yafixu64a0.execute-api.us-east-1.amazonaws.com/Prod/token',{
          method: 'POST',
          body:  JSON.stringify({
            "id": id,
            "idLanguage": idLanguage
          })
        })
        const responseJson = await response.json()
        commit('getToken', responseJson)
      } catch(error) {
        console.log(error)
      }
    },
    async getInformationCV({commit, dispatch}, requestToken){
      try{
        await dispatch('getToken', requestToken)
        const response = await fetch('https://yasuqbg7d2.execute-api.us-east-1.amazonaws.com/Prod/getinformationcv',{
          method: 'POST',
          body:  JSON.stringify({
            "body": this.state.responseToken.token})
        })
        const responseJson = await response.json()
        commit('getInformationCV', responseJson)
        commit('setLoad', true)
      } catch(error) {
        console.log(error)
      }
    }
  },
  modules: {
  }
})

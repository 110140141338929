<template>
  <div class="container component">
    <div v-if="load == false" class="load">
      <div class="spinner-grow" role="status"></div>
      <div class="spinner-grow" role="status"></div>
      <div class="spinner-grow" role="status"></div>
    </div>
    <div v-else class="row">
      <div class="col-lg-4">
        <Profile
          v-if="responseInformationCV"
          :responseInformationCV="responseInformationCV"
        ></Profile>
      </div>
      <div class="col-lg-8">
        <Skills
          v-if="responseInformationCV"
          :responseInformationCV="responseInformationCV"
        ></Skills>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Profile from "./Profile.vue";
import Skills from "./Skills.vue";
export default {
  components: { Profile, Skills },
  computed: {
    ...mapState(["responseInformationCV", "load"]),
  },
};
</script>

<style scoped>
.load {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.row {
  justify-content: space-around;
}
.component {
  background: #ffffff;
  border: outset;
  box-shadow: 4px 5px 4px -1px rgba(0,0,0,0.74);
  height: 100%;
  width: 100%;
  border-radius: 25px;
}

@media (min-width: 320px) and (max-width: 2559.99px) {
  .component {
    height: auto;
  }
}
@media (min-width: 2560px)  {
  .component {
    height: 70%;
  }
}

@media (min-width: 1440px) and (max-width: 2559.99px) {
  .component {
    margin-bottom: 50px;
  }
}

</style>>


<template>
  <router-view />
</template>
<script>
    export default {
        name: 'app',
        created () {
            document.title = "CVEduardoSolórzano";
        }
    }
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Domine&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");

#app {
  font-family: "Domine", serif;
}
h3 {
  font-family: "Anton", sans-serif;
}
html {
  height: 100%;
  width: 100%;
  margin: 0;
}

body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
}

#app {
  min-height: 100vh;
  min-width: 100vw;
  background: linear-gradient(180deg, #0250e3 50%, #FFFFFF 50%);
  overflow-x: hidden;
}
</style>

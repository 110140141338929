import es from '../src/assets/i18n/es.json'
import en from '../src/assets/i18n/en.json'
import { createI18n } from "vue3-i18n";

const i18n = createI18n({
    locale : localStorage.getItem('lang') || 'es',
    messages : {
        en : en,
        es : es
    }
})

export default i18n;

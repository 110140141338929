<template>
  <nav class="navbar navbar-expand-lg navbar-dark header">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">{{ $t("header.home") }}</a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ $t("header.project") }}
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <a class="dropdown-item" href="https://www.marycar.mx" target="_blank">{{
                  $t("header.projects.marycar")
                }}</a>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" 
            href="https://www.linkedin.com/in/eduardo-solórzano-alor-40a86b161" 
            target="_blank">{{ $t("header.linkedin") }}</a>
          </li>
        </ul>
        <select
          class="form-select select"
          @change="handleChange($event)"
          v-model="lang"
        >
          <option value="es">{{ $t("header.languageEs") }}</option>
          <option value="en">{{ $t("header.languageEn") }}</option>
        </select>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    const lang = localStorage.getItem("lang") || "es";
    return {
      lang: lang,
    };
  },
  methods: {
    handleChange(event) {
      localStorage.setItem("lang", event.target.value);
      window.location.reload();
    },
    ...mapActions(["getInformationCV"]),
  },
};
</script>

<style>
.header {
  background: #0250e3;
  height: 200px;
}
.select {
  width: 10%;
  margin: 0.6rem;
}
@media (min-width: 320px) and (max-width: 1023.99px) {
  .select {
    width: auto;
  }
  .header {
    margin-bottom: 30px;
  }
}
</style>